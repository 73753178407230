import { Button, Flex, Spinner, Text, TextField } from "@radix-ui/themes";
import { useEffect, useState, useRef } from "react";
import { useSheets } from "../../hooks/useSheetsSetup";
import { useAuth } from "../../contexts/AuthContext";
import {
  CopyIcon,
  Link1Icon,
  OpenInNewWindowIcon,
} from "@radix-ui/react-icons";
import toast from "react-hot-toast";

export function CreatingSheet() {
  const [loading, setLoading] = useState(true);
  const { startOnboarding, error, step, sheetId } = useSheets();
  const { addRefreshToken } = useAuth();
  const hasRunEffect = useRef(false);

  const LoadingIndicator = () => {
    let stepText = "Loading";
    if (step === "createSheet") {
      stepText = "Creating Sheet";
    } else if (step === "addTransactions") {
      stepText = "Fetching Transactions";
    } else if (step === "addAccounts") {
      stepText = "Fetching Bank Accounts";
    }

    return (
      <Flex direction="column" gap="2">
        <Flex align="center" gap="2">
          <Spinner />
          <Text size="5" weight="bold">
            {stepText}
          </Text>
        </Flex>
      </Flex>
    );
  };

  const ErrorMessage = () => {
    return (
      <Flex direction="column" gap="2">
        <Text color="red">{error}</Text>
      </Flex>
    );
  };

  useEffect(() => {
    const handleCreateSheet = async () => {
      setLoading(true);
      await addRefreshToken();
      await startOnboarding();
      setLoading(false);
    };

    if (step === "createSheet" && !hasRunEffect.current) {
      handleCreateSheet();
      hasRunEffect.current = true;
    }
  }, [step]);

  if (error) {
    return <ErrorMessage />;
  }

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Flex>
      {sheetId && (
        <Flex direction="column" gap="4" width="100%">
          <Text size="5" weight="bold">
            Sheet Created!
          </Text>

          <TextField.Root
            value={`https://docs.google.com/spreadsheets/d/${sheetId}/edit`}
            contentEditable={false}
            variant="soft"
            size="3"
            onClick={() => {
              navigator.clipboard.writeText(
                `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
              );
              toast.success("Link copied to clipboard");
            }}
          >
            <TextField.Slot>
              <Link1Icon />
            </TextField.Slot>
          </TextField.Root>

          <Flex gap="4" align="center">
            <Button
              size="2"
              onClick={() => {
                window.open(
                  `https://docs.google.com/spreadsheets/d/${sheetId}/edit`,
                  "_blank"
                );
              }}
            >
              <OpenInNewWindowIcon />
              Open
            </Button>

            <Button
              variant="soft"
              size="2"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
                );
              }}
            >
              <CopyIcon />
              Copy Link
            </Button>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}
