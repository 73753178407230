import { BrowserRouter, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { Theme } from "@radix-ui/themes";
import { Toaster } from "react-hot-toast";
import { ProtectedRoute } from "./components/auth/ProtectedRoute";
import "./styles/theme.css";

// Pages
import { Landing } from "./pages/landing";
import { Dashboard } from "./pages/dashboard";
import { OnboardingPage } from "./pages/onboarding";
import { Settings } from "./pages/settings";
import { NotFoundPage } from "./pages/404";
import { SignInPage } from "./pages/login";
import { PrivacyPolicyPage } from "./pages/privacy";

function App() {
  return (
    <Theme
      accentColor="indigo"
      grayColor="slate"
      radius="large"
      scaling="95%"
      appearance="light"
    >
      <AuthProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/onboarding"
              element={
                <ProtectedRoute>
                  <OnboardingPage />
                </ProtectedRoute>
              }
            />

            <Route path="/login" element={<SignInPage />} />

            <Route path="/privacy" element={<PrivacyPolicyPage />} />

            <Route
              path="/settings"
              element={
                <ProtectedRoute>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </BrowserRouter>
      </AuthProvider>
      <Toaster />
    </Theme>
  );
}

export default App;
