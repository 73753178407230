import { useState, useEffect } from "react";
import { supabase } from "../lib/supabase";

type OnboardingStep = "payment" | "plaid" | "accounts" | "complete";

interface OnboardingHook {
  isOnboarded: boolean;
  isLoading: boolean;
  onboardingStep: OnboardingStep;
  setOnboardingStep: (step: OnboardingStep) => void;
  completeOnboarding: () => Promise<void>;
  verifySession: (sessionId: string) => Promise<void>;
}

export function useOnboarding(): OnboardingHook {
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [onboardingStep, setOnboardingStep] =
    useState<OnboardingStep>("payment");

  // returns true if user is onboarded
  const checkOnboardingStatus = async () => {
    const { data } = await supabase
      .from("users")
      .select("is_onboarded")
      .single();

    setIsOnboarded(data?.is_onboarded ?? false);
    setIsLoading(false);
  };

  const completeOnboarding = async () => {
    await supabase.from("users").update({ is_onboarded: true }).single();
    setIsOnboarded(true);
  };

  // verify session and move to plaid step
  const verifySession = async (sessionId: string) => {
    try {
      const response = await fetch(`/api/stripe/session/${sessionId}`);
      if (!response.ok) {
        throw new Error("Failed to verify session");
      }
      const session = await response.json();

      // Assuming session verification is successful, move to the next step
      setOnboardingStep("plaid");
    } catch (error) {
      console.error("Session verification error:", error);
    }
  };

  useEffect(() => {
    checkOnboardingStatus();
  }, []);

  return {
    isOnboarded,
    isLoading,
    onboardingStep,
    setOnboardingStep,
    completeOnboarding,
    verifySession,
  };
}
