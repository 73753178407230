import { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Spinner } from "@radix-ui/themes";

export const SignInPage = () => {
  const { signInWithGoogle } = useAuth();

  useEffect(() => {
    signInWithGoogle();
  }, [signInWithGoogle]);

  return <Spinner />;
};
