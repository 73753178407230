import { Container, Heading, Text, Link, Flex } from "@radix-ui/themes";
import Navbar from "../../components/layout/Navbar";
import Footer from "../../components/layout/Footer";

export const NotFoundPage = () => {
  return (
    <Container size="2">
      <Navbar />
      <Flex direction="column" align="center" mt="9" gap="4">
        <Heading size="8" weight="medium">
          Page Not Found
        </Heading>
        <Text size="4" color="gray">
          Oops! The page you are looking for does not exist.
        </Text>
        <Text>
          Is this a mistake?{" "}
          <Link href="/contact" color="blue">
            Let us know
          </Link>
          .
        </Text>
      </Flex>
    </Container>
  );
};
