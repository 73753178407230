import { Flex, Link, Separator, Text } from "@radix-ui/themes";

const Footer = ({ showFooter }: { showFooter: boolean }) => {
  if (!showFooter) return null; // Conditionally render based on footerHide

  return (
    <footer>
      {/* Your footer content goes here */}
      <Flex
        direction="column"
        justify="center"
        align="center"
        py="4"
        gap="3"
        mb="4"
      >
        <Separator mb="1" size="4" orientation="horizontal" />

        <Flex align="center" gap="2">
          <Link href="/" color="gray">
            Home
          </Link>
          <Link href="/contact" color="gray">
            Contact
          </Link>
          <Link href="/privacy" color="gray">
            Privacy Policy
          </Link>
        </Flex>

        <Text size="2" color="gray">
          © {new Date().getFullYear()} All rights reserved.
        </Text>
      </Flex>
    </footer>
  );
};

export default Footer;
