import { ArrowRightIcon } from "@radix-ui/react-icons";
import { Text, Button, Flex, Spinner } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { useOnboarding } from "../../hooks/useOnboarding";

export function OnboardingCard() {
  const navigate = useNavigate();

  const { isOnboarded, isLoading } = useOnboarding();

  if (isLoading) {
    return <Spinner />;
  }

  if (!isOnboarded) {
    return (
      <Flex direction="column" gap="2">
        <Text size="5" weight="medium">
          Get Started
        </Text>
        <Text size="3" color="gray">
          Complete a quick onboarding process to start using all features.
        </Text>
        <Button mt="2" size="3" onClick={() => navigate("/onboarding")}>
          <ArrowRightIcon />
          Start Onboarding
        </Button>
      </Flex>
    );
  } else return <></>;
}
