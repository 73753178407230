import {
  Heading,
  Flex,
  Spinner,
  Text,
  Button,
  Box,
  Separator,
} from "@radix-ui/themes";

import { useOnboarding } from "../../hooks/useOnboarding";
import { Layout } from "../../components/layout/Layout";
import { LinkBreak2Icon, TrashIcon } from "@radix-ui/react-icons";

export const Settings = () => {
  const { isOnboarded, isLoading } = useOnboarding();

  if (isLoading) {
    return (
      <Flex align="center" justify="center" style={{ minHeight: "100vh" }}>
        <Spinner size="2" />
      </Flex>
    );
  }

  return (
    <Layout
      headerContent={<Heading size="5">Settings</Heading>}
      allowBack={true}
    >
      {/* settings content */}
      <Flex direction="column" gap="4" py="5">
        <Heading size="5" weight="medium">
          Account Settings
        </Heading>
        <Separator size="4" />

        <Flex direction="column" gap="4">
          <Flex direction="column" gap="2">
            <Text size="3" weight="medium">
              Remove my Google account
            </Text>
            <Text size="3" color="gray">
              This will remove all your linked accounts with Finlink, but your
              existing sheets will not be deleted.
            </Text>
            <Box>
              <Button variant="outline" color="gray">
                <LinkBreak2Icon />
                Unlink
              </Button>
            </Box>
          </Flex>
        </Flex>

        <Heading size="5" weight="medium" mt="4">
          Notifications
        </Heading>
        <Separator size="4" />

        <Heading size="5" weight="medium" mt="4">
          Privacy Settings
        </Heading>
        <Separator size="4" />
      </Flex>
    </Layout>
  );
};
