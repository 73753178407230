import { useEffect, useState } from "react";
import { supabase } from "../lib/supabase";

export const usePlaidItems = () => {
  const [accounts, setAccounts] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchAccounts = async () => {
      setIsLoading(true);

      const {
        data: { session },
      } = await supabase.auth.getSession(); // Get session from Supabase
      const sessionAuthToken = session?.access_token; // Extract the access token

      const response = await fetch(`/api/plaid/accounts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionAuthToken}`, // Ensure to include the auth token if required
        },
      });

      // Log the response status and body for debugging
      const data = await response.json();
      setAccounts(data);
      setIsLoading(false);
    };

    fetchAccounts();
  }, []);

  // Function to activate multiple accounts
  const setActiveAccounts = async (accountIds: string[], isActive: boolean) => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession(); // Get session from Supabase
      const sessionAuthToken = session?.access_token; // Extract the access token

      // Create array of account updates - set selected accounts to isActive, others to false
      const accountUpdates = accounts.map((account) => ({
        accountId: account.account_id,
        isActive: accountIds.includes(account.account_id) ? isActive : false,
      }));

      const response = await fetch(`/api/supabase/activate-accounts`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionAuthToken}`,
        },
        body: JSON.stringify({ accountUpdates }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error updating accounts: ${errorData.message}`);
      }

      return true;
    } catch (error) {
      console.error("Error toggling account activation:", error);
    }
  };

  // Get account active status given account ids
  const getAccountActiveStatus = async (accountIds: string[]) => {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    const userId = session?.user?.id;

    const { data, error } = await supabase
      .from("bank_accounts")
      .select("account_id, active")
      .in("account_id", accountIds)
      .eq("user_id", userId);

    if (error) {
      console.error("Error getting account active status:", error);
      return [];
    }

    return data.map((account) => ({
      accountId: account.account_id,
      isActive: account.active,
    }));
  };

  // Function to get plaid items
  const getPlaidItems = async () => {
    try {
      const {
        data: { session },
      } = await supabase.auth.getSession(); // Get session from Supabase
      const sessionAuthToken = session?.access_token; // Extract the access token

      const response = await fetch(`/api/plaid/items`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionAuthToken}`, // Use the session auth token here
        },
      });

      const data = await response.json();
      console.log("data", data);

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error updating accounts: ${errorData.message}`);
      }

      return true;
    } catch (error) {
      console.error("Error toggling account activation:", error);
    }
  };

  return {
    accounts,
    error,
    isLoading,
    setActiveAccounts,
    getAccountActiveStatus,
    getPlaidItems,
  };
};
