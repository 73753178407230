import { Heading, Flex } from "@radix-ui/themes";

import { OnboardingCard } from "./OnboardingCard";
import { Layout } from "../../components/layout/Layout";
import { SheetCard } from "./SheetCard";
import { LinkedAccounts } from "./LinkedAccounts";

export const Dashboard = () => {
  return (
    <Layout
      headerContent={
        <Heading size="5" weight="medium">
          Dashboard
        </Heading>
      }
    >
      {/* onboarding card */}
      <OnboardingCard />

      {/* sheet card */}
      <Flex direction="column" gap="4" py="5">
        <SheetCard />
      </Flex>

      {/* information card */}
      <Flex direction="column" gap="4" py="5">
        <LinkedAccounts />
      </Flex>
    </Layout>
  );
};
