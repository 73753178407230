import { motion } from "framer-motion";

const fadeUpVariants = {
  initial: { opacity: 0, y: 40 },
  animate: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 40 },
};

const FadeUp = ({
  children,
  delay = 0,
}: {
  children: React.ReactNode;
  delay?: number;
}) => {
  return (
    <motion.div
      initial="initial"
      animate={{
        opacity: 1,
        y: 0,
        transition: { delay, duration: 0.4, ease: [0.6, 0.05, 0.4, 1] }, // Slower start, faster end
      }}
      exit="exit"
      variants={fadeUpVariants}
      transition={{ duration: 0.4, ease: [0.6, 0.05, 0.4, 1] }} // Slower start, faster end
    >
      {children}
    </motion.div>
  );
};

export default FadeUp;
