import { useEffect, useState } from "react";
import { supabase } from "../lib/supabase";

export const useSheets = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [sheetIds, setSheetIds] = useState<string[]>([]);

  useEffect(() => {
    getUserSheets();
  }, []);

  const getUserSheets = async () => {
    setIsLoading(true);
    const {
      data: { user },
    } = await supabase.auth.getUser();
    if (!user) return;

    const { data, error } = await supabase
      .from("google_sheets")
      .select("sheet_id")
      .eq("user_id", user.id);

    if (error) {
      setError(error.message);
      return;
    }

    setSheetIds(data.map((sheet) => sheet.sheet_id));
    setIsLoading(false);
    return data;
  };
  return {
    sheetIds,
    isLoading,
    error,
  };
};
