import {
  Container,
  Heading,
  Text,
  Link,
  Flex,
  Separator,
} from "@radix-ui/themes";
import Navbar from "../../components/layout/Navbar";
import Footer from "../../components/layout/Footer";

export const PrivacyPolicyPage = () => {
  return (
    <Container size="2">
      <Navbar />
      <Flex direction="column" mt="9" gap="4">
        <Heading size="8" weight="medium">
          Privacy Policy
        </Heading>
        <Text size="4" color="gray">
          Last updated - 10th February 2025
        </Text>
      </Flex>

      <Separator size="4" my="6" />

      <Flex direction="column" gap="4">
        <Heading size="5" weight="medium">
          1. Introduction
        </Heading>
        <Text size="4" color="gray">
          This Privacy Policy explains how we collect, use, store, and share
          your information when you use Finlink. By using Finlink, you agree to
          these practices.
        </Text>

        <Heading size="5" weight="medium">
          2. Information Collection
        </Heading>
        <Flex direction="column" gap="3">
          <Text size="4" color="gray">
            <Text as="span" weight="medium">
              Direct Data:{" "}
            </Text>
            We collect personal details (e.g., name, email) during registration
            and via Google OAuth.
          </Text>
          <Text size="4" color="gray">
            <Text as="span" weight="medium">
              Usage Data:{" "}
            </Text>
            We gather app interaction data, cookies, and device information to
            enhance our services.
          </Text>
          <Text size="4" color="gray">
            <Text as="span" weight="medium">
              Third-Party Data:
            </Text>
          </Text>
          <Flex direction="column" gap="2" pl="4">
            <Text size="4" color="gray">
              • Google OAuth: Provides basic profile info (email, profile
              picture) solely for authentication and improving user experience.
            </Text>
            <Text size="4" color="gray">
              • Plaid: Enables secure, limited access to financial data without
              storing login credentials or sensitive bank details.
            </Text>
          </Flex>
        </Flex>

        <Heading size="5" weight="medium">
          3. Information Use
        </Heading>
        <Flex direction="column" gap="2">
          <Text size="4" color="gray">
            To manage user accounts, personalize your experience, and improve
            Finlink's services.
          </Text>
          <Text size="4" color="gray">
            Data received via Google OAuth and Plaid is used strictly as
            described and in accordance with their privacy policies (links
            provided below).
          </Text>
        </Flex>

        <Heading size="5" weight="medium">
          4. Data Sharing & Disclosure
        </Heading>
        <Flex direction="column" gap="2">
          <Text size="4" color="gray">
            We share data only with trusted third-party service providers (e.g.,
            hosting, analytics) under strict confidentiality agreements.
          </Text>
          <Text size="4" color="gray">
            Disclosure may occur when required by law, during business
            transfers, or to protect our or users' rights.
          </Text>
        </Flex>

        <Heading size="5" weight="medium">
          5. Data Storage, Retention & Security
        </Heading>
        <Flex direction="column" gap="2">
          <Text size="4" color="gray">
            Your data is stored securely using encryption and secure protocols.
          </Text>
          <Text size="4" color="gray">
            We retain your data only as long as necessary and have procedures in
            place for breach notifications.
          </Text>
        </Flex>

        <Heading size="5" weight="medium">
          6. User Rights & Controls
        </Heading>
        <Flex direction="column" gap="2">
          <Text size="4" color="gray">
            You can access, correct, or delete your personal information at any
            time.
          </Text>
          <Text size="4" color="gray">
            Opt-out options are available for non-essential data collection and
            marketing communications.
          </Text>
        </Flex>

        <Heading size="5" weight="medium">
          7. Compliance & Disclaimers
        </Heading>
        <Flex direction="column" gap="2">
          <Text size="4" color="gray">
            • Google OAuth: Data is used solely for authentication and enhancing
            your experience. Please review Google's Privacy Policy.
          </Text>
          <Text size="4" color="gray">
            • Plaid: We use Plaid for secure financial data access; sensitive
            financial credentials are not stored. Please review Plaid's Privacy
            Policy.
          </Text>
          <Text size="4" color="gray">
            • Finlink is provided "as is" without warranty. We are not liable
            for indirect or consequential damages.
          </Text>
          <Text size="4" color="gray">
            • This policy may be updated periodically; your continued use
            constitutes acceptance of any changes.
          </Text>
          <Text size="4" color="gray">
            • Governing law: United States
          </Text>
        </Flex>

        <Heading size="5" weight="medium">
          8. Contact Us
        </Heading>
        <Text size="4" color="gray">
          For questions or concerns regarding this Privacy Policy, please
          contact us at{" "}
          <Link href="mailto:privacy@finlink.app">privacy@finlink.app</Link>
        </Text>
      </Flex>

      <Footer showFooter={true} />
    </Container>
  );
};
