import {
  CopyIcon,
  Link1Icon,
  OpenInNewWindowIcon,
  TrashIcon,
} from "@radix-ui/react-icons";
import {
  Text,
  Button,
  Flex,
  TextField,
  Spinner,
  Card,
  Heading,
} from "@radix-ui/themes";
import toast from "react-hot-toast";
import { useSheets } from "../../hooks/useSheets";
import { useOnboarding } from "../../hooks/useOnboarding";

export function SheetCard() {
  const { sheetIds, isLoading } = useSheets();
  const { isOnboarded } = useOnboarding();

  if (!isOnboarded) return <></>;

  if (isLoading) {
    return <Spinner />;
  }

  if (sheetIds.length === 0) {
    return <></>;
  }

  const sheetId = sheetIds[0];

  return (
    <Card variant="classic">
      <Flex direction="column" gap="4" p="2">
        <Heading size="5" weight="medium">
          My Finlink Sheet
        </Heading>

        <TextField.Root
          value={`https://docs.google.com/spreadsheets/d/${sheetId}/edit`}
          contentEditable={false}
          variant="soft"
          size="3"
          onChange={() => {}}
          onClick={() => {
            navigator.clipboard.writeText(
              `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
            );
          }}
        >
          <TextField.Slot>
            <Link1Icon />
          </TextField.Slot>
        </TextField.Root>

        <Flex justify="between" pr="2">
          <Flex gap="4" align="center">
            <Button
              size="2"
              onClick={() => {
                window.open(
                  `https://docs.google.com/spreadsheets/d/${sheetId}/edit`,
                  "_blank"
                );
              }}
            >
              <OpenInNewWindowIcon />
              Open
            </Button>

            <Button
              variant="soft"
              size="2"
              onClick={() => {
                navigator.clipboard.writeText(
                  `https://docs.google.com/spreadsheets/d/${sheetId}/edit`
                );
                toast.success("Link copied to clipboard");
              }}
            >
              <CopyIcon />
              Copy Link
            </Button>
          </Flex>

          <Flex>
            <Button size="2" variant="outline" color="red">
              <TrashIcon />
              Delete
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Card>
  );
}
