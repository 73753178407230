import { LockClosedIcon, RocketIcon, StarIcon } from "@radix-ui/react-icons";
import {
  Button,
  Flex,
  Heading,
  RadioCards,
  Spinner,
  Text,
} from "@radix-ui/themes";

interface TierSelectionProps {
  onValueChange: (value: string) => void;
  onCheckout: () => void;
  isStripeCheckoutLoading: boolean;
  defaultValue?: string;
}

export function TierSelection({
  onValueChange,
  onCheckout,
  defaultValue = "regular",
  isStripeCheckoutLoading,
}: TierSelectionProps) {
  return (
    <Flex direction="column" gap="2">
      <Heading size="5">Choose Your Plan</Heading>
      <Text mb="4">Select the plan that suits your needs best.</Text>
      <RadioCards.Root
        defaultValue={defaultValue}
        onValueChange={onValueChange}
      >
        <RadioCards.Item value="regular">
          <Flex align="center" gap="4" p="1" width="100%">
            <StarIcon color="gray" width={22} height={22} />
            <Flex direction="column" gap="1">
              <Text weight="medium" size="4">
                Regular
              </Text>
              <Text size="2">Connect up to 5 accounts for personal use</Text>
              <Text size="2" style={{ fontFamily: "Geist Mono, monospace" }}>
                $5 / mo.
              </Text>
            </Flex>
          </Flex>
        </RadioCards.Item>
        <RadioCards.Item value="premium">
          <Flex align="center" gap="4" p="1" width="100%">
            <RocketIcon color="gray" width={22} height={22} />
            <Flex direction="column" gap="1">
              <Text weight="medium" size="4">
                Premium
              </Text>
              <Text size="2">Connect up to 12 accounts for power users</Text>
              <Text size="2" style={{ fontFamily: "Geist Mono, monospace" }}>
                $10 / mo.
              </Text>
            </Flex>
          </Flex>
        </RadioCards.Item>
      </RadioCards.Root>

      <Button onClick={onCheckout} variant="solid" size="3" mt="4">
        {isStripeCheckoutLoading ? (
          <Spinner />
        ) : (
          <>
            <LockClosedIcon />
            Secure Checkout
          </>
        )}
      </Button>
    </Flex>
  );
}
