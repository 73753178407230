import {
  Container,
  Heading,
  Button,
  Flex,
  Text,
  Card,
  Box,
  Link,
  Grid,
} from "@radix-ui/themes";
import { useAuth } from "../../contexts/AuthContext";
import Footer from "../../components/layout/Footer";
import Navbar from "../../components/layout/Navbar";
import { ArrowDownIcon, ArrowRightIcon } from "@radix-ui/react-icons";
import FadeUp from "../../components/transition/FadeUp";

export const Landing = () => {
  const { signInWithGoogle } = useAuth();

  return (
    <Container size="2">
      <Navbar />
      <Flex gap="9" direction="column" mt="9" px={{ initial: "4", md: "0" }}>
        {/* Landing Section */}
        <FadeUp>
          <Flex
            direction="column"
            gap="6"
            justify="center"
            minHeight={{ initial: "40vh", md: "50vh" }}
          >
            <Heading size="8" weight="medium" align="center">
              Automate Your Personal Finance
            </Heading>
            <Text size="4" align="center">
              Securely connect your bank account(s) using Plaid and
              automatically sync your transactions to a pre-built Google Sheets
              budgeting template.
            </Text>

            <Flex direction="column" gap="2" align="center">
              <Box>
                <Button size="3" onClick={signInWithGoogle}>
                  Sign Up with Google
                </Button>
              </Box>
              <Text size="3" color="gray">
                Already have an account? <Link href="/login">Login</Link>
              </Text>
            </Flex>

            <FadeUp delay={0.2}>
              <Box
                width="100%"
                height="350px"
                style={{
                  backgroundColor: "black",
                  opacity: 0.2,
                  borderRadius: "20px",
                }}
              />
            </FadeUp>
          </Flex>
        </FadeUp>

        {/* Set up in 3 steps */}
        <FadeUp delay={0.4}>
          <Flex
            direction="column"
            align={{ initial: "start", md: "center" }}
            gap="6"
            mt="4"
          >
            <Heading size="7" weight="medium">
              Set up in{" "}
              <span style={{ color: "var(--blue-10)" }}>5 minutes</span>
            </Heading>
            <Flex
              direction={{ initial: "column", md: "row" }}
              mx={{ initial: "0", md: "-6" }}
              align={{ initial: "start", md: "center" }}
              gap="2"
            >
              <Card>
                <Link href="/login">Sign up with Google</Link>
              </Card>
              <Box ml={{ initial: "6", md: "0" }}>
                <Box display={{ initial: "block", md: "none" }}>
                  <ArrowDownIcon width="20" height="20" />
                </Box>
                <Box display={{ initial: "none", md: "block" }}>
                  <ArrowRightIcon width="20" height="20" />
                </Box>
              </Box>
              <Card>
                <Text size="3" align="center">
                  Connect your account(s)
                </Text>
              </Card>
              <Box ml={{ initial: "6", md: "0" }}>
                <Box display={{ initial: "block", md: "none" }}>
                  <ArrowDownIcon width="20" height="20" />
                </Box>
                <Box display={{ initial: "none", md: "block" }}>
                  <ArrowRightIcon width="20" height="20" />
                </Box>
              </Box>
              <Card>
                <Text size="3" align="center">
                  Google Sheet dropped into your Drive
                </Text>
              </Card>
            </Flex>
          </Flex>
        </FadeUp>

        {/* How it works */}
        <FadeUp delay={0.6}>
          <Flex
            direction="column"
            align={{ initial: "start", md: "center" }}
            gap="8"
            mt="4"
          >
            <Heading size="7" weight="medium">
              How it works
            </Heading>

            <Grid columns={{ initial: "1", md: "2" }} gap="6">
              <Flex direction="column" gap="2">
                <Text size="5" weight="medium">
                  Sign In & Setup
                </Text>
                <Text size="3">
                  Sign in with your Google account so we can access Sheets
                  needed to create your Finlink budgeting template.
                </Text>
                <Text size="3" color="gray">
                  We will never access your other files or data. See our{" "}
                  <Link href="/privacy">privacy policy</Link>.
                </Text>
              </Flex>
              <Flex direction="column" gap="2">
                <Text size="5" weight="medium">
                  Link Your Bank Accounts
                </Text>
                <Text size="3">
                  Connect your bank securely through Plaid using
                  industry-standard security measures.
                </Text>
                <Text size="3" color="gray">
                  Finlink is registered with Plaid and authorized by financial
                  institutions like Chase, Wells Fargo, and Bank of America.
                </Text>
              </Flex>
              <Flex direction="column" gap="2">
                <Text size="5" weight="medium">
                  Auto-Updates to Your Sheet
                </Text>
                <Text size="3">
                  Your transactions automatically sync to your pre-built Google
                  Sheets template, keeping your budget up-to-date on a daily
                  basis.
                </Text>
              </Flex>
              <Flex direction="column" gap="2">
                <Text size="5" weight="medium">
                  Cancel Anytime
                </Text>
                <Text size="3">
                  Enjoy full control—cancel the service at any time with no
                  commitments or hidden fees.
                </Text>
              </Flex>
            </Grid>
          </Flex>
        </FadeUp>

        {/* Contact */}
        <FadeUp delay={0.8}>
          <Flex
            direction="column"
            align={{ initial: "start", md: "center" }}
            gap="6"
            mt="4"
            mb="4"
          >
            <Heading size="7" weight="medium">
              Have more questions?
            </Heading>
            <Text size="3" align={{ md: "center" }}>
              Check out our <Link href="/faq">FAQ</Link> or contact us at{" "}
              <Link href="mailto:hello@finlink.co">hello@finlink.co</Link>.
            </Text>
          </Flex>
        </FadeUp>
      </Flex>

      <Footer showFooter={true} />
    </Container>
  );
};
