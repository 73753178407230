import { useOnboarding } from "../../hooks/useOnboarding";
import { PlanTier, useStripeCheckout } from "../../hooks/useStripeCheckout";
import { useCustomPlaidLink } from "../../hooks/usePlaidLink";
import { useEffect, useState } from "react";
import { Heading, Button, Flex } from "@radix-ui/themes";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TierSelection } from "./TierSelection";
import { Layout } from "../../components/layout/Layout";
import AccountSelection from "./AccountSelection";
import ConnectBank from "./ConnectBank";
import { CreatingSheet } from "./CreatingSheet";
import { ArrowLeftIcon } from "@radix-ui/react-icons";

export const OnboardingPage = () => {
  const navigate = useNavigate();

  // Onboarding
  const {
    onboardingStep,
    completeOnboarding,
    setOnboardingStep,
    verifySession,
  } = useOnboarding();

  // Stripe Checkout
  const { handlePayment, isLoading: isStripeCheckoutLoading } =
    useStripeCheckout();
  const [searchParams] = useSearchParams();
  const [selectedTier, setSelectedTier] = useState<string>("regular");

  // listen for session_id in url params for stripe checkout callback
  useEffect(() => {
    const sessionId = searchParams.get("session_id");
    if (sessionId && onboardingStep === "payment") {
      verifySession(sessionId);
    }
  }, [searchParams, onboardingStep, verifySession]);

  // Plaid Link
  const { open, handlePlaidLink, ready, linkToken } = useCustomPlaidLink(() => {
    setOnboardingStep("accounts");
  });

  return (
    <Layout
      headerContent={
        <Heading size="5" weight="medium">
          First Time Setup
        </Heading>
      }
      allowBack={true}
      customBackButton={
        <Button
          size="2"
          radius="full"
          variant="ghost"
          color="gray"
          onClick={() => navigate("/dashboard")}
        >
          <ArrowLeftIcon />
          Back
        </Button>
      }
    >
      {/* Development Stage Buttons */}
      <Flex direction="row" gap="2" mb="4">
        <Button onClick={() => setOnboardingStep("payment")}>1</Button>
        <Button onClick={() => setOnboardingStep("plaid")}>2</Button>
        <Button onClick={() => setOnboardingStep("accounts")}>3</Button>
        <Button onClick={() => setOnboardingStep("complete")}>4</Button>
      </Flex>

      {/* First Step: Select Plan */}
      <Flex direction="column" gap="4" pt="2">
        {onboardingStep === "payment" && (
          <Flex direction="column" gap="5">
            <TierSelection
              onValueChange={setSelectedTier}
              onCheckout={() => handlePayment(selectedTier as PlanTier)}
              isStripeCheckoutLoading={isStripeCheckoutLoading}
            />
          </Flex>
        )}

        {/* Second Step: Connect Bank Account */}
        {onboardingStep === "plaid" && (
          <Flex direction="column" gap="5">
            <ConnectBank
              ready={ready}
              handlePlaidLink={() => handlePlaidLink()}
            />
          </Flex>
        )}

        {/* Third Step: Select Accounts */}
        {onboardingStep === "accounts" && (
          <Flex direction="column" gap="5">
            <AccountSelection
              onComplete={() => setOnboardingStep("complete")}
            />
          </Flex>
        )}

        {/* Third Step: Create Sheet */}
        {onboardingStep === "complete" && (
          <Flex direction="column" gap="5">
            <CreatingSheet />
          </Flex>
        )}
      </Flex>
    </Layout>
  );
};
