import { MixerHorizontalIcon } from "@radix-ui/react-icons";
import {
  Text,
  Button,
  Flex,
  Skeleton,
  Separator,
  Badge,
} from "@radix-ui/themes";
import { usePlaidItems } from "../../hooks/usePlaidItems";
import { useCallback, useEffect, useState } from "react";
import { useOnboarding } from "../../hooks/useOnboarding";

export function LinkedAccounts() {
  const { isLoading, accounts, getAccountActiveStatus } = usePlaidItems();
  const { isOnboarded } = useOnboarding();
  const [activeAccounts, setActiveAccounts] = useState<any[]>([]);

  // Get active accounts
  useEffect(() => {
    if (accounts.length > 0) {
      const getActiveAccounts = async () => {
        const activeAccounts = await getAccountActiveStatus(
          accounts.map((account) => account.account_id)
        );
        setActiveAccounts(activeAccounts);
      };
      getActiveAccounts();
    }
  }, [accounts]);

  const renderActiveBadge = useCallback(
    (accountId: string) => {
      const activeAccount = activeAccounts.find(
        (activeAccount) => activeAccount.accountId === accountId
      );
      return activeAccount?.isActive ? (
        <Badge color="green" radius="full">
          Active
        </Badge>
      ) : (
        <Badge color="gray" radius="full">
          Inactive
        </Badge>
      );
    },
    [activeAccounts]
  );

  if (!isOnboarded) return <></>;

  return (
    <Flex direction="column" gap="4" width="100%">
      <Flex justify="between">
        <Text size="5" weight="medium">
          Linked Accounts
        </Text>
        <Button variant="soft" size="1">
          <MixerHorizontalIcon />
          Account Settings
        </Button>
      </Flex>

      <Separator size="4" />

      <Flex direction="column" gap="4">
        {isLoading ? (
          <Flex gap="2" direction="column">
            <Skeleton height="40px" width="100%" />
            <Skeleton height="40px" width="100%" />
            <Skeleton height="40px" width="100%" />
          </Flex>
        ) : (
          accounts.map((account) => (
            <Flex direction="column" key={account.account_id}>
              <Flex direction="row" justify="between" align="center">
                <Flex direction="column">
                  <Text size="3" weight="medium">
                    {account.name}
                  </Text>
                  <Text color="gray">Account ending in {account.mask}</Text>
                </Flex>
                {renderActiveBadge(account.account_id)}
              </Flex>
            </Flex>
          ))
        )}
      </Flex>
    </Flex>
  );
}
