import { createClient } from "@supabase/supabase-js";

const supabaseUrl = "https://ojhymhgsvkwoidaceqha.supabase.co";
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Im9qaHltaGdzdmt3b2lkYWNlcWhhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjUyMTE3MDAsImV4cCI6MjA0MDc4NzcwMH0.4MmSrZRFpuxDRnBE9qwW0dPNaA7L-Byv2CH2iA0O4t4";

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error("Missing Supabase environment variables");
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey);
