import { Flex, Link, Text, Box, Button, Popover } from "@radix-ui/themes";
import { useAuth } from "../../contexts/AuthContext";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
const Navbar = () => {
  const { signInWithGoogle } = useAuth();

  const Links = [
    { label: "Home", href: "/" },
    { label: "FAQ", href: "/faq" },
    { label: "Contact", href: "/contact" },
  ];

  const renderLinks = () => {
    return Links.map((link) => (
      <Link href={link.href} color="gray">
        {link.label}
      </Link>
    ));
  };

  return (
    <Flex
      direction="row"
      justify="between"
      my="6"
      px={{ initial: "4", md: "0" }}
    >
      {/* Left side - Logo always visible */}

      <Flex align="center" gap="4">
        <Box>
          <Text size="4" color="gray" weight="medium">
            Finlink
          </Text>
        </Box>

        <Flex direction="row" gap="3" display={{ initial: "none", md: "flex" }}>
          {renderLinks()}
        </Flex>
      </Flex>

      {/* Desktop Navigation */}
      <Box display={{ initial: "none", md: "block" }}>
        <Button size="2" variant="soft" onClick={signInWithGoogle}>
          Sign in with Google
        </Button>
      </Box>

      {/* Mobile Navigation */}
      <Box display={{ initial: "block", md: "none" }}>
        <Popover.Root>
          <Popover.Trigger>
            <Button variant="ghost">
              <HamburgerMenuIcon width="20" height="20" />
            </Button>
          </Popover.Trigger>
          <Popover.Content>
            <Box p="2">
              <Flex direction="column" gap="2">
                {renderLinks()}
                <Button size="2" onClick={signInWithGoogle}>
                  Sign in with Google
                </Button>
              </Flex>
            </Box>
          </Popover.Content>
        </Popover.Root>
      </Box>
    </Flex>
  );
};

export default Navbar;
